import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Popup from 'reactjs-popup';
import { graphql } from 'gatsby';
import CountUp from 'react-countup';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import lkw2 from '../1Pics/44424.jpg';
import guy from '../1Pics/552.jpg';
import 'reactjs-popup/dist/index.css';
import PLZ from '../components/PLZ';
import Booking from '../1Pics/booking.png';
import Offer from '../1Pics/email.png';
import Delivery from '../1Pics/shipped.png';
import Star from '../assets/star.svg';
import Check from '../assets/check.svg';
import MadeInDe from '../assets/madeInDe.svg';
import DSGVO from '../assets/dsgvo.svg';
import OffsetIcon from '../assets/offsett.svg';
import TrashIcon from '../assets/trash.svg';

const Index = ({ data }) => {
  const [currentNum, setCurrentNum] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentNum < data.rezension.edges.length - 1) {
        const num = currentNum + 1;
        setCurrentNum(num);
      } else {
        setCurrentNum(0);
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  const getLeftPos = index => {
    if (index === currentNum) {
      return '50%';
    }
    if (
      currentNum === index + 1 ||
      (index === data.rezension.edges.length - 1 && currentNum === 0)
    ) {
      return '300%';
    }
    if (
      currentNum === index - 1 ||
      (currentNum === data.rezension.edges.length - 1 && index === 0)
    ) {
      return '-200%';
    }

    return '-300%';
  };

  const getOpacity = index => {
    if (
      currentNum === index + 1 ||
      currentNum === index + 2 ||
      (index === data.rezension.edges.length - 1 && currentNum === 0) ||
      (index === data.rezension.edges.length - 2 && currentNum === 0) ||
      currentNum === index - 1 ||
      currentNum === index - 2 ||
      (currentNum === data.rezension.edges.length - 1 && index === 0) ||
      (currentNum === data.rezension.edges.length - 2 && index === 0)
    ) {
      return '0';
    }
    return '1';
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>re.cycular</title>
        <description>
          : `re.cycular is a platform to help the world become a cleaner place. Get rid of
          everything you do not need anymore. As soon as possible. Bulky garbage transportation
          service.,
        </description>
      </Helmet>

      <section className="pt-20 md:pt-20">
        <div>
          <div className="container mx-auto px-8 lg:flex">
            <div className="text-center lg:text-left lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-blue-500 leading-none">
                re.cycular - der schnellste Sperrmüll Abholservice.
              </h1>
              <p className="text-xl lg:text-2xl mt-6">
                Innerhalb von 24h holen wir bei dir Zuhause alles ab. Für maximal 99 Cent pro
                Kilogramm.
              </p>
              <p className="text-xl lg:text-2xl mt-6 ">That's it. </p>

              <p className="mt-8 md:mt-12">
                <Popup
                  trigger={
                    <button
                      className="button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button"
                      type="button"
                    >
                      Jetzt Buchen{' '}
                    </button>
                  }
                  modal
                >
                  <PLZ />
                </Popup>
              </p>
              <div />
            </div>
            <div className="lg:w-1/2">
              <img src={guy} alt="Workers" width="500" height="600" />
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto mb-16 mt-10">
        <h3 className="text-5xl font-semibold text-center">Unsere Kunden lieben uns:</h3>
        <p className="text-center mb-2 text-gray-600">
          Bewertungen {data.rezension.edges.length} &#183; Hervorragend
        </p>
        <a
          href="https://de.trustpilot.com/review/www.recycular.de"
          className="stars__container"
          target="__blank"
        >
          <div className="stars__star">
            <Star />
          </div>
          <div className="stars__star">
            <Star />
          </div>
          <div className="stars__star">
            <Star />
          </div>
          <div className="stars__star">
            <Star />
          </div>
          <div className="stars__star stars__star--gray">
            <Star />
            <div className="stars__star--half" />
          </div>
          <p className="mt-2 ml-3 text-gray-600">4,5</p>
        </a>

        <div className="stars__cards mt-12">
          {data.rezension.edges.map((element, index) => (
            <div
              className="stars__card"
              key={element.node.name}
              style={{
                left: getLeftPos(index),
                opacity: getOpacity(index)
              }}
            >
              <div className="stars__name">
                <div className="stars__avatar">
                  {element.node.name.split(' ')[0].charAt(0)}
                  {element.node.name.split(' ')[1] && element.node.name.split(' ')[1].charAt(0)}
                </div>
                <h5>{element.node.name}</h5>
              </div>
              <hr />
              <div className="stars__info">
                <div className="stars__small">
                  {[...Array(5)].map((e, i) => (
                    <div
                      className={
                        i >= element.node.ration
                          ? 'stars__star stars__star--gray stars__star--small'
                          : 'stars__star stars__star--small'
                      }
                      key={e}
                    >
                      <Star />
                    </div>
                  ))}
                </div>
                <small className="mt-2 text-gray-600">
                  {element.node.dateAdded.split('-')[2]}.{element.node.dateAdded.split('-')[1]}.
                  {element.node.dateAdded.split('-')[0]}
                </small>
              </div>
              <p>{element.node.rezension}</p>
            </div>
          ))}
        </div>
      </div>
      <section id="Ablauf" className="py-20 lg:pb-10 lg:pt-20">
        <div className="container mx-auto ">
          <h2 className="text-3xl lg:text-5xl font-semibold text-center ">
            In 90 Sekunden Abholung Buchen
          </h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <img src={Booking} alt="Booking" width="50" height="300" />
                <p className="font-semibold text-xl text-left"> Schritt 1:</p>
                <p className="font-semibold text-xl text-left mb-8">
                  Wunsch Abholtermin auswählen & Details zur Abholung angeben
                </p>

                <p className="mt-4 text-left">
                  Wähle kostenlos deinen Wunsch Abholtag und Zeitfenster aus.
                </p>
                <p className="mt-4 text-left">
                  Gib' im automatisierten Buchungssystem die Gegenstände an, die abgeholt werden
                  sollen.
                </p>
                <p className="mt-4">
                  Im Anschluss kannst Du deine favorisierte Online-Bezahlmethode auswählen.
                </p>
              </Card>
            </div>

            <div className="flex-1 px-3">
              <Card className="mb-8">
                <img src={Offer} alt="Delivery" width="50" height="300" />
                <p className="font-semibold text-xl text-left">Schritt 2:</p>
                <p className="font-semibold text-xl text-left"> Weitere Informationen & Checkout</p>
                <p className="mt-4">
                  Du musst nur noch Deine Adresse und Kontaktdaten mit uns teilen und bezahlen, um
                  die Buchung abzuschließen.
                </p>
                <p className="mt-4">
                  Im Anschluss erhälst Du eine Bestätigungsmail zu deiner Buchung, sowie eine
                  Rechnung.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <img src={Delivery} alt="Delivery" width="50" height="300" />
                <p className="font-semibold text-xl text-left">Schritt 3</p>
                <p className="font-semibold text-xl text-left"> Abholung am Wunschtermin</p>
                <p className="mt-4">
                  Im gebuchten Zeitfenster kommen wir Co2-kompensiert zu Dir nach Hause und laden
                  deine Abholung ein. Im Anschluss entsorgen wir alles ordnungsgemäß.
                </p>
                <p className="mt-4">re.cycular</p>
                <p className="mt-8 md:mt-12">
                  <a
                    href="/Ablauf/"
                    className="button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button hover:text-white"
                  >
                    Mehr erfahren{' '}
                  </a>
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <SplitSection
        id="Vorteile"
        primarySlot={<img src={lkw2} alt="tonnen" />}
        secondarySlot={
          <div>
            <Card className="mb-8">
              <p className="text-3xl lg:text-5xl font-semibold text-left">Unsere Vorteile</p>
              <p className="text-xl text-left mt-6">
                <span>&#10003;</span> Abholung innerhalb von 24h an deinem Wunsch-Zeitfenster.
              </p>

              <p className="text-xl text-left mt-6">
                <span>&#10003;</span> Egal was es ist, wir holen es ab.
              </p>
              <p className="text-xl text-left mt-6">
                <span>&#10003;</span> Du kannst schnell und einfach Online bezahlen.
              </p>
              <p className="text-xl text-left mt-6">
                <span>&#10003;</span> Jede Abholung ist Co2-Positiv - Wir kompensieren jede Fahrt
                und dein Gerümpel und holen es somit klimaneutral ab.
              </p>
              <p className="mt-8 md:mt-12">
                <a
                  href="/Vorteile/"
                  className="button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button hover:text-white"
                >
                  Mehr erfahren{' '}
                </a>
              </p>
            </Card>
          </div>
        }
      />

      <section className="container mx-auto grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 gap-3">
        <div className="badge-germany rounded-lg flex-col sm:flex-row">
          <ul>
            <li className="badge__check">
              {' '}
              <Check />
              Entwickler in Deutschland
            </li>
            <li className="badge__check">
              {' '}
              <Check />
              Qualifizierter Support
            </li>
            <li className="badge__check">
              {' '}
              <Check />
              Deutscher Datenschutz
            </li>
            <li className="badge__check">
              {' '}
              <Check />
              Sicheres Hosting
            </li>
          </ul>
          <MadeInDe />
          <DSGVO />
        </div>
        <div className="badge__co2 rounded-lg relative">
          <div className="w-1/2 flex flex-col justify-center items-center">
            <OffsetIcon className="text-primary" />
            <h5 className="text-black text-2xl">
              <CountUp start={0} end={145} duration={2.75} suffix=" kg CO2" />
            </h5>
            <h6 className="text-black">kompensiert</h6>
          </div>
          <div className="line" />
          <div className="w-1/2 flex flex-col justify-center items-center">
            <TrashIcon className="text-primary" />
            <h5 className="text-black text-2xl">
              <CountUp start={0} end={4000} duration={2.75} suffix=" kg" />
            </h5>
            <h6 className="text-black">Sperrmüll abgeholt</h6>
          </div>
        </div>
      </section>
      <section className="container mx-auto my-2 py-8 px-5 bg-gray-100 rounded-lg text-center">
        <h3 className="text-3xl md:text-5xl font-semibold">
          Bist du bereit loszuwerden was dich belastet?
        </h3>
        <p className="mt-8 text-xl font-light">Dann wähle jetzt deinen Abholtermin aus.</p>
        <p className="mt-8">
          <Popup
            trigger={
              <button
                className="button py-3 px-8 button bg-primary hover:bg-primary-darker rounded text-white button"
                type="button"
              >
                Jetzt Buchen{' '}
              </button>
            }
            modal
          >
            <PLZ />
          </Popup>
        </p>
      </section>
    </Layout>
  );
};

export default Index;

export const PageQuery = graphql`
  query {
    rezension: allContentfulRezension {
      edges {
        node {
          name
          dateAdded
          rezension
          ration
        }
      }
    }
  }
`;
